import React from 'react';
import './Process.scss';
import { Container } from 'react-bootstrap';
import ProcessIcon from '@components/icons/ProcessIcon';
import CTAText from '@components/elements/CTAText/CTAText';
import { parseHTML } from '@lib/utils';
import CTALink from '@components/elements/CTALink';
import ArrowRight from '@components/icons/ArrowRight';
import clsx from 'clsx';

const Process = ({module}) => {
  const { cta_text, design_title } = module
  return (
    <div className="process-container section-m">
      <Container className="our-services-container">
        <div className="process-steps">
          {design_title && <div className="design_title">
          {parseHTML(design_title?.data?.design_title)}
          </div>}
          <ProcessIcon />
        </div>
        <div className="bt-top">
        <CTAText data={cta_text} newclass="button button-orange" />
        </div>
      </Container>
    </div>
  );
};



export default Process;
